import { Link } from '@mui/material'
import {
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridFilterInputValue,
  GridPreProcessEditCellProps,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { startCase } from 'lodash'
import { IFlattenedOrder } from 'screens/Orders/types'
import { renderAsChip } from 'utility/dataGrid'
import { dateOfBirthFormatter } from 'utility/formatters'
import { invoiceUrl } from 'utility/stripe'
import {
  AddressState,
  IAddress,
  ShippingOrderProvider,
  ShippingOrderIncludable,
  UserSensorKind
} from 'types'
import { FORMATTED_PRODUCT_TYPE, ProductType, FORMATTED_INCLUDABLE } from './productNames'
import { FORMATTED_PROVIDER } from './providers'

export type FilterStartingWith<Set, Needle extends string> = Set extends `${Needle}${infer _X}`
  ? Set
  : never

export const dateFilterOperators = getGridDateOperators().filter(
  (operator) => operator.value !== 'not'
)

const MAX_POSITION = 1000 // value for unset position

// sort fields by position field in descending order
export function sortByPosition<T extends { position?: number }[]>(array: T) {
  return array.sort((a, b) => {
    const aPosition = a.position || MAX_POSITION
    const bPosition = b.position || MAX_POSITION

    if (aPosition > bPosition) {
      return 1
    }

    if (aPosition === bPosition) {
      return 0
    }

    return -1
  })
}

export const addressColumns: (IDataGridColumn & {
  position?: number
  field: FilterStartingWith<keyof IFlattenedOrder, 'address.'> | `generated.${string}`
})[] = [
  {
    field: 'address.name',
    headerName: 'Address Full Name',
    editable: true,
    width: 200
  },
  {
    field: 'address.street',
    headerName: 'Street',
    editable: true,
    width: 300
  },
  {
    field: 'address.street2',
    headerName: 'Street 2',
    editable: true,
    width: 200
  },
  {
    field: 'address.city',
    headerName: 'City',
    editable: true,
    width: 140
  },
  {
    field: 'address.zipCode',
    headerName: 'Zip Code',
    editable: true,
    width: 100
  },
  {
    field: 'address.state',
    type: 'singleSelect',
    editable: true,
    valueOptions: Object.values(AddressState).sort(),
    headerName: 'State',
    width: 80
  },
  {
    field: 'address.country',
    headerName: 'Country',
    editable: true,
    width: 100,
    filterOperators: [
      ...getGridStringOperators(),
      {
        value: 'not',
        getApplyFilterFn: () => null,
        InputComponent: GridFilterInputValue
      }
    ]
  },
  {
    field: 'generated.address.fullShippingAddress',
    headerName: 'Full Shipping Address',
    width: 440,
    sortable: false,
    filterable: false,
    valueGetter: (params) => {
      const address: IAddress = params.row.address

      const fullAddress = (address.street + ' ' + (address.street2 || '')).trim()

      return `${fullAddress}, ${address.city}, ${address.state}, ${address.zipCode}, ${address.country}`
    }
  }
]

export const userColumns: (IDataGridColumn & {
  position?: number
  field: FilterStartingWith<keyof IFlattenedOrder, 'user.'> | 'user.fullName'
})[] = [
  {
    field: 'user.id',
    headerName: 'User ID',
    minWidth: 100,
    type: 'number',
    valueFormatter: (params) => params.value
  },
  {
    field: 'user.email',
    headerName: 'User Email',
    flex: 1,
    position: 1,
    minWidth: 160
  },
  {
    field: 'user.firstName',
    editable: true,
    headerName: 'User First Name',
    width: 180
  },
  {
    field: 'user.lastName',
    editable: true,
    headerName: 'User Last Name',
    width: 180
  },
  {
    field: 'user.fullName',
    headerName: 'User Full Name',
    width: 200,
    position: 2,
    valueGetter: (params) => {
      const { row } = params
      return `${row.user.firstName} ${row.user.lastName}`
    }
  },
  {
    field: 'user.phoneNumber',
    headerName: 'User Phone Number',
    width: 180
  },
  {
    field: 'user.dateOfBirth',
    headerName: 'User Date of Birth',
    width: 170,
    type: 'date',
    editable: true,
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value ? dateOfBirthFormatter(params.value) : null
  },
  {
    field: 'user.sex',
    headerName: 'User Sex',
    width: 100
  }
]

const orderColumns: (IDataGridColumn & { position?: number; field: keyof IFlattenedOrder })[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    type: 'number',
    valueFormatter: (params) => params.value
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 160,
    type: 'singleSelect',
    editable: true,
    groupable: true,
    valueOptions: Object.values(ShippingOrderProvider),
    valueGetter: ({ row }) => row.provider || '',
    valueFormatter: (params) => FORMATTED_PROVIDER[params.value as keyof typeof FORMATTED_PROVIDER],
    filterOperators: [
      ...getGridSingleSelectOperators(),
      {
        value: 'isNotEmpty',
        getApplyFilterFn: () => null
      },
      {
        value: 'isEmpty',
        getApplyFilterFn: () => null
      }
    ]
  },
  { field: 'product', headerName: 'Product', width: 160, editable: true, groupable: true },
  {
    field: 'productType',
    headerName: 'Product Type',
    width: 160,
    editable: true,
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(ProductType),
    valueFormatter: (params) =>
      FORMATTED_PRODUCT_TYPE[params.value as keyof typeof FORMATTED_PRODUCT_TYPE]
  },
  {
    field: 'includable',
    headerName: 'Included',
    width: 160,
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(ShippingOrderIncludable),
    valueFormatter: (params) =>
      FORMATTED_INCLUDABLE[params.value as keyof typeof FORMATTED_INCLUDABLE]
  },
  {
    field: 'sensorKind',
    headerName: 'Sensor Kind',
    width: 220,
    editable: true,
    groupable: true,
    type: 'singleSelect',
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const cellValue = params.props.value
      const hasError = cellValue === UserSensorKind.DexcomG7
      return { ...params.props, error: hasError }
    },
    valueOptions: Object.values(UserSensorKind),
    valueFormatter: (params) => startCase(params.value)
  },
  {
    field: 'replacementRequest.replacementReason',
    headerName: 'Replacement Reason',
    renderCell: renderAsChip,
    type: 'singleSelect',
    groupable: true,
    minWidth: 250,
    flex: 1,
    editable: true
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 125,
    type: 'number',
    editable: true,
    groupable: true,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const hasError = params.props.value < 0
      return { ...params.props, error: hasError }
    }
  },
  {
    field: 'invoice.stripeId',
    headerName: 'Invoice ID',
    width: 240,
    renderCell: (params) => (
      <Link href={invoiceUrl(params.value)} target="_blank">
        {params.value}
      </Link>
    ),
    sortable: false
  },
  { field: 'notes', headerName: 'Notes', flex: 1, minWidth: 140, editable: true },
  {
    field: 'createdAt',
    type: 'date',
    headerName: 'Created At',
    width: 160,
    filterOperators: dateFilterOperators
  },
  {
    field: 'updatedAt',
    type: 'date',
    headerName: 'Updated At',
    width: 160,
    filterOperators: dateFilterOperators
  }
]

export const columns: (IDataGridColumn & {
  field: keyof IFlattenedOrder | `generated.${string}` | 'user.fullName'
})[] = sortByPosition([...orderColumns, ...userColumns, ...addressColumns])

const editableColumns = columns.filter(({ editable }) => editable)

export const editableOrderColumns = editableColumns.filter(({ field }) => !field.includes('.'))

export const editableAddressColumns = editableColumns.filter(({ field }) =>
  field.startsWith('address.')
)

export const editableUserColumns = editableColumns.filter(({ field }) => field.startsWith('user.'))
