import { Button } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { ICoachAssignment } from 'types'

interface IBulkUpdateProviderProps {
  selectedRows: Map<GridRowId, any>
  bulkUpdate: (ids: any[], params: { [key: string]: any }) => void
}

export const BulkUpdateProvider = (props: IBulkUpdateProviderProps) => {
  const { selectedRows, bulkUpdate } = props

  const coachAssignments: ICoachAssignment[] = Array.from(selectedRows.values())

  const handleOnClick = () => {
    const ids = coachAssignments.map((coachAssignment) => coachAssignment.id)
    bulkUpdate(ids, {})
  }

  return (
    <Button
      variant="contained"
      size="medium"
      sx={styles.enrollButton}
      onClick={handleOnClick}
      disabled={selectedRows.size === 0}
    >
      Send Engagement Message
    </Button>
  )
}

const styles = {
  enrollButton: {
    position: 'absolute',
    bottom: '-45px',
    right: 0
  }
}
