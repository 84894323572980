export enum LocationPaths {
  AdminPanel = '/admin-panel',
  AllCompletedNotifications = '/admin-panel/all-completed-notifications',
  ReplacementRequests = '/replacement-requests',
  Users = '/users',
  Login = '/login',
  ResetPasswordRequest = '/reset-password-request',
  NotFound = '/not-found',
  Approvals = '/approvals',
  Surveys = '/surveys',
  Financials = '/financials',
  Knowledge = '/knowledge',
  SupportTickets = '/support-tickets',
  AccountRequests = '/account-requests',
  Presentation = '/presentation',
  NutritionistHome = '/nutritionist-home',
  MembersManagement = '/members-management',
  Dpp = '/dpp',
  NutritionistsOrganization = '/nutritionists-organization'
}

export interface ILocationState {
  from: ILocationStateFrom
}

export interface ILocationStateFrom {
  pathname: LocationPaths
}
