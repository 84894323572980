import { UserApprovalRequestKind, UserApprovalRequestState } from 'types'

export const QUICK_FILTERS_DEFINITIONS = [
  {
    name: 'Approvals',
    items: [
      {
        columnField: 'state',
        operatorValue: 'is',
        value: UserApprovalRequestState.PendingApproval
      }
    ]
  },
  {
    name: 'Rejections',
    items: [
      {
        columnField: 'state',
        operatorValue: 'is',
        value: UserApprovalRequestState.Rejected
      },
      {
        columnField: 'kind',
        operatorValue: 'is',
        value: UserApprovalRequestKind.Prescription
      }
    ]
  },
  {
    name: 'Prescription',
    items: [
      {
        columnField: 'kind',
        operatorValue: 'is',
        value: UserApprovalRequestKind.Prescription
      }
    ]
  },
  {
    name: 'Research',
    items: [
      {
        columnField: 'kind',
        operatorValue: 'is',
        value: UserApprovalRequestKind.Research
      }
    ]
  },
  {
    name: 'GLP',
    items: [
      {
        columnField: 'kind',
        operatorValue: 'is',
        value: UserApprovalRequestKind.Glp
      }
    ]
  },
  {
    name: 'Did Not Complete CGM Software Only',
    items: [
      {
        columnField: 'state',
        operatorValue: 'is',
        value: UserApprovalRequestState.DidNotComplete
      },
      {
        columnField: 'kind',
        operatorValue: 'isAnyOf',
        value: [UserApprovalRequestKind.Research, UserApprovalRequestKind.Prescription]
      },
      {
        columnField: 'user.lastCoreSubscription.primaryProduct.softwareOnly',
        operatorValue: 'is',
        value: 'true'
      }
    ]
  },
  {
    name: 'Did Not Complete CGM',
    items: [
      {
        columnField: 'state',
        operatorValue: 'is',
        value: UserApprovalRequestState.DidNotComplete
      },
      {
        columnField: 'kind',
        operatorValue: 'isAnyOf',
        value: [UserApprovalRequestKind.Research, UserApprovalRequestKind.Prescription]
      },
      {
        columnField: 'user.lastCoreSubscription.primaryProduct.softwareOnly',
        operatorValue: 'is',
        value: 'false'
      }
    ]
  }
]
